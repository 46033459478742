import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function AboutUs() {
  return (
    <section className="card_page_container">
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/kreon.jpg'
              description='Kreon single cover art.'
              youtube='https://youtu.be/5KTgpHIwE-M?si=2YjM6Qztlx0UShcO'
              spotify='https://open.spotify.com/track/6mVsIAZr6YSuPkP4gejm9E?si=123ff5f649e14565'
              itunes='https://music.apple.com/us/album/kreon-single/1750263564'
              deezer='https://deezer.page.link/SUJnKHiEbL2Xpt9r5'
            />
            <CardItem
              src='images/lelek.png'
              description='Lélekgyötrő single cover art.'
              youtube='https://youtu.be/IPsmBWV-ou0'
              spotify='https://open.spotify.com/track/1ajUrt9De2kRTUE9TFMrLI?si=oRM58-zoTRCpMqFeUKgaag'
              itunes='https://music.apple.com/us/album/l%C3%A9lekgy%C3%B6tr%C5%91-single/1488267313'
              deezer='https://www.deezer.com/us/album/119457232'
            />
            <CardItem
              src='images/sorsom.png'
              description='Sorsom vagy single cover art.'
              youtube='https://youtu.be/MAWUSGDi6Qs'
              spotify='https://open.spotify.com/track/7zXfVdUOw9iSHkwiv4RSYG?si=qhHm5PYTTbi-895zocOGRw'
              itunes='https://music.apple.com/us/album/sorsom-vagy-single/1555938678'
              deezer='https://www.deezer.com/us/album/211182532'
            />
          </ul>
          </div>
        </div>
    </section>
  );
}

export default AboutUs;