import React from 'react';
import './Concerts.css'

function Concerts() {
  return (
    <section id='concerts' className="concert_page_container">
      <div className="concerts_container">
        <h1>KONCERTEK</h1>
        <div className="concert-soon-container">
          <p>HAMAROSAN!</p>
        </div>
      </div>
    </section>
  )
}

export default Concerts;
