import React from 'react';
import './YoutubeSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Ratio } from 'react-bootstrap';

function MainSection() {
  return (
    <div id='youtube-hero-container'>
      <div className='youtube-hero-container'>
        <div className="youtube-video-and-title-container">
          <Ratio aspectRatio="16x9">
            <iframe src="https://www.youtube.com/embed/5KTgpHIwE-M?si=0uXWvIhJxLknCMEX"
              width="1080"
              height="608"
              title="DREAM OF INSOMNIA - KREON[Hivatalos videó]" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen>
            </iframe>
          </Ratio>
          <div class="youtube-button-container">
          <a id="youtubelink" class="youtube-button" href='https://www.youtube.com/@DreamOfInsomnia' target="_blank" rel="noreferrer">TÖBB VIDEÓ</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainSection;